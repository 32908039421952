'use client';
import { Button } from '@/src/features/ui/button/button';
import Heading from '../../ui/heading';
import { getCdnPath } from '@/src/utils/get-cdn-path';
import Image from 'next/image';
import { InnerContainer } from '../../ui/common/inner-container';

type PageParams = {
  status?: string | '404';
};

export const Error404 = ({ status }: PageParams) => {
  return (
    <InnerContainer>
      <section className="flex h-full flex-col items-center justify-center gap-6 pb-16 pt-[60px] md:flex-row-reverse md:gap-[90px] md:pb-64 md:pt-10">
        <figure className="relative h-[200px] w-[200px] shrink-0 md:h-[400px] md:w-[400px]">
          <Image src={getCdnPath('/shared/error.png')} alt={status ?? ''} fill />
        </figure>

        <section className="flex flex-col items-center gap-6 md:items-start md:gap-10">
          <article className="flex flex-col gap-2 md:gap-6">
            <Heading tagType={'h1'} cls="md:text-[42px] font-semibold text-[28px] md:text-start text-center">
              Bir hata ile karşılaştık
            </Heading>
            <Heading
              tagType={'p'}
              cls="text-[#454545] md:text-xl text-base md:text-start text-center md:leading-normal leading-5"
            >
              Üzgünüz, ancak bu sayfaya şu an ulaşamıyoruz. Aradığınız diğer bilgilere ana sayfa üzerinden
              ulaşabilirsiniz.
            </Heading>
          </article>
          <Button
            className="h-12 w-[159px] rounded-md md:px-4 md:py-3 md:text-base md:font-semibold"
            text={'Ana sayfaya dön'}
            onClick={() => (window.location.href = '/')}
          />
        </section>
      </section>
    </InnerContainer>
  );
};
