import classNames from 'classnames';
import { RelLink } from '../rel-link';
import { ButtonArrow } from '../icons/icons';

type Props = {
  children?: React.ReactNode;
  type?: string;
  text: string | null;
  isNegative?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  otherProps?: any;
  href?: string;
  paleButton?: boolean;
  orangeButton?: boolean;
  textButton?: boolean;
  hasArrow?: boolean;
  subsButton?: boolean;
};

export const Button: React.FC<Props> = ({
  type = 'button',
  children,
  text,
  isNegative = false,
  disabled,
  onClick,
  className,
  otherProps,
  paleButton,
  orangeButton,
  textButton,
  hasArrow,
  subsButton,
  href,
  ...rest
}) => {
  const props = {
    ...rest,
    ...otherProps,
    onClick,
    className: classNames(
      'flex flex-row border-solid items-center justify-center',
      {
        'bg-white font-bold text-purple500 border-purple500 border ': isNegative,
        'opacity-50 font-bold cursor-not-allowed border-purple500': disabled && !subsButton,
        'border-black100 font-semibold text-black800 cursor-not-allowed shadow-paleButton': paleButton,
        'w-full rounded-lg py-4 px-8 border border-purple500 bg-opacity-5 text-purple500 text-xs font-bold leading-[18px] ':
          orangeButton,
        'z-50 text-white bg-orange500 font-semibold': subsButton && !disabled,
        'bg-orange25 opacity-100 border-none cursor-not-allowed text-white font-semibold':
          subsButton && !isNegative && disabled,
        'bg-none text-orange500': textButton,
        'md:p-4 md:gap-2 md:item-center': hasArrow,
        'bg-purple500 font-bold text-white border-purple500':
          !isNegative && !orangeButton && !textButton && !subsButton,
      },
      className
    ),
  };

  if (href) {
    return (
      <RelLink scroll={false} href={href} {...props}>
        {text}
      </RelLink>
    );
  }

  return (
    <button type={type} {...props} disabled={disabled}>
      {children}
      {text}
      {hasArrow && <ButtonArrow className="h-[24px] w-[24px] shrink-0" />}
    </button>
  );
};
